#home{
  background-image: linear-gradient(to bottom, white, #eee) ;
  padding-bottom: 50px;
  width: 100%;
}

#home > .banner{
  background: url('../../assets/home-banner-bg-heavy.jpg') fixed no-repeat top center;
}

#home .banner-slogang{
  color: #888;
  border-left: #319846 solid 4px;
  padding-left: 5px;
}

#home > .banner> .banner-partB > .img{
	background-image: url('../../assets/bannerImage.jpg'), linear-gradient(to bottom right, red, #319846, #2b86c5);
  border-radius: 50% 10%;
}

#home h1{
  font-size: 3rem;
}

@media screen and (max-width: 1050px) {

  #home > .banner{
    background-position: center;
  }
  
  #home > .banner > .banner-partA{
    text-shadow: unset;
  }

  #home > .banner .banner-slogang{
    width: 30%;
    margin: auto;
    border: none;
    color: #777;
  }
  
  #home > .banner > .banner-partB{
    display: none;
  }
}

@media screen and (max-width: 850px) {

  #home > .banner{
    background-position: top center;
  }
  
}

@media screen and (max-width: 750px){

  #home .banner{
    padding: unset 0 unset 0;
    background: url('../../assets/home-banner-bg.jpg') fixed no-repeat top center;
  }
  #home h1{
    font-size: 2rem;
  }

  #home h3{
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 650px){
  #home h1{
    font-size: 1.3rem;
  }

  #home > .banner .banner-slogang{
    width: 50%;
  }
}

@media screen and (max-width: 450px) {

  #home .banner{
     padding-top: 120px;
  }

  #home .banner .banner-partA h2{
    font-size: 2rem;
  }
  #home .banner .banner-partA .banner-description{
    font-size: 1.5rem;
  }
  #home .banner .banner-partA .banner-slogang{
    font-size: 1.7rem;
  }
}