.servicesMenu{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  cursor: pointer;
  z-index: 10;
  position: relative;
  text-align: center;
}

.servicesMenu:hover> .servicesMenu__button{
  color: #319846;
}

.servicesMenu__button{
  display: flex;
  align-items: center;
  justify-content: center;
}

.servicesMenu__button--icon{
  vertical-align: top;
  margin-left: 5px;
  transition: 200ms;
}

.servicesMenu__items{
  overflow: hidden;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  min-width: 300px;
  border-radius: 5px;
  background-color: white;
  color: black;
  text-transform: capitalize;
  transition: 200ms;
}

@keyframes viewSubMenu{
  0% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    max-height: 50px;
    padding: 5px 0;
    overflow:unset;
  }
  30% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    max-height: 150px;
    padding: 5px 0;
    overflow:unset;
  }
  60% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    max-height: 250px;
    padding: 5px 0;
    overflow:unset;
  }
  100% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    max-height: unset;
    max-height: unset;
    padding: 5px 0;
    overflow:unset;
  }
}


.servicesMenu:hover .servicesMenu__items {
  animation: viewSubMenu 0ms forwards;
}

.servicesMenu:hover .servicesMenu__button--icon{
  transform: rotate(90deg);
}

.servicesMenu__items__link, .servicesMenu__items{
  width: 100%;
}

.servicesMenu__items__link{
  border-bottom: #888 solid 1px;
}

.servicesMenu__items__link:last-child{
  border-bottom: unset;
}

.servicesMenu__items__link:hover{
  background-color: #319846;
  color: white;
}

.servicesMenu:hover .servicesMenu__items__link{
  padding: 10px 0px;
}

@media screen and (max-width: 750PX) {
 .servicesMenu__items{
   position: unset;
   border-radius: unset;
 } 

  .servicesMenu:hover> .servicesMenu__button{
    color: white;
  }

}