.send-mail{
  background-color: var(--main-color);
  color: white;
  padding: 30px;
  border-radius: 10px;
  font-size: 32px;
}

.contact{
  display: flex;
  align-items: center;
  width: 100%;
  height: 90vh;
  min-height: 90vh;
}

.contact form,
.contact-titles,
.contact-content
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-titles, .contact-content{
  width: 50%;
  height: 100%;
}

.contact-titles{
  background: url('../../assets/servicesBg-heavy.jpg') fixed center;
  color: white;
  border-right: solid 20px #319846;
  position: relative;
  min-width: 50%;
}

.contact-header{
  width: 90%;
  text-align: center;
}

.contact-header>h1{
  font-size: 3rem;
}

.contact-header>p{
  color: #319846;
  text-decoration: underline;
}

.contact-header>p>p{
  font-size: 2rem;
  color: #aaa;
  font-family: manjari;
  
}

.contact-inboxs{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  bottom: 100px;
  color: white;
}

.contact-inboxs-item{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 25px;
  background-color: #319846;
}

.contact-titles .contact-inboxs-item{
  box-shadow: 0px 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.contact-content .contact-inboxs-item{
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
}

.contact-inboxs-item:hover{
  transition: 100ms;
  background-color: red;
}

.contact-inboxs-item .icon{
  font-size: 2rem;
}

.contact-content{
  width: 50%;
  height: 100%;
  display: flex;
}

.contact-content .contact-header > p {
  font-size:2rem;
  display: flex;
  align-items: baseline;
}

.contact-content .contact-header > p > p{
  font-size: 2rem;
  color: black;
}

.contact__form{
  width: 70%;
  margin: auto;
  border: none;
  border-radius: 5px;
  font-family: manjari;
}

.contact-input{
  display: flex;
  align-items: center;
  margin: 8px 0;
  position: relative;
}

.contact-input, .contact-textarea{
  width: 80%;
}

.contact-input > div{
  position: absolute;
  background-color: #319846;
}

.contact-textarea > div{
  background-color: red;
  margin-bottom: 10px;
}

.contact-input>div, .contact-textarea > div{
  margin-left: 5px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), 2px -2px 5px rgba(0, 0, 0, 0.1), -2px 2px 5px  rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-input > div > .icon, .contact-textarea > div > .icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
}

.contact-input input{
  height: 60px;
  padding-left: 60px;
}

.contact-input input, .contact-textarea textarea{
  width: 100%;
  border: unset;
  outline: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), 2px -2px 5px rgba(0, 0, 0, 0.1), -2px 2px 5px  rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1.2rem;
}

.contact-textarea{
  margin-top: 15px;
}

.contact-textarea textarea{
  min-height: 300px;
  max-height: 300px;
  max-width: 100%;
  min-width: 100%;
  padding: 5px;
}

.contact-submit{
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.contact-submit input{
  width: 30%;
  margin: auto;
  height: 60px;
  border: none;
  background-color: #319846;
  color: white;
  font-weight: 800;
  font-size: 1.2rem;
  font-family: manjari;
  cursor: pointer;
  border-radius: 10px;
}


.contact-submit input:hover{
  transform: scale(1.05);
  transition: 300ms;
}

@media screen and (max-width: 1050px) {
 .contact >*>*{
   transform: scale(0.9);
 }
}

@media screen and (max-width: 950px) {

  .contact{
    height: 70vh;
  }
  .contact >*>*{
   transform: scale(0.8);
 }

 .contact-inboxs{
   position: unset;
   bottom: 100px;
 }

 .contact-titles{
    background: url('../../assets/servicesBg.jpg') fixed center;
  }

 .contact-content{
   width: 80%;
 }

  .contact__form{
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .contact{
    flex-direction: column;
    width: 100%;
    height: unset;
  }

  .contact-titles,
  .concact-content{
    width: 100%;
  }

  .contact-titles{
    background-position: bottom center ;
    border-right: unset;
    border-bottom: solid 10px #319846;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
    position: unset;
    text-align: center;
    padding: 50px 0;
  }

  .contact-content{
    padding-top: unset;
  }

  .contact-header>h1{
    font-size: 3rem;
  }
  
  .contact-header>p>p{
    font-size: 2rem;
  }
  
}

@media screen and (max-width: 550px) {
  .contact-titles{
    padding-top: 130px;
  }
  
  .contact-inboxs-item{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 15px;
    background-color: #319846;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5), 2px -2px 5px rgba(0, 0, 0, 0.5), -2px 2px 5px rgba(0, 0, 0, 0.5), -2px -2px 5px rgba(0, 0, 0, 0.5);
  }
  
  .contact-inboxs-item .icon{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .contact-header>h1{
    font-size: 2rem;
  }
  
  .contact-header>p>p{
    font-size: 1.5rem;
  }

  .contact-content{
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .contact__form > *{
    width: 100%;
  } 
}