.commandForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 30px;
  text-align: center;
}

.commandForm__icon{
  position: absolute;
  width: 60px;
  height: 60px;
  margin: auto;
  top: 51.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5), -2px -2px 5px rgba(0,0,0,0.5);
  border-radius: 50%;
}

.commandForm__icon img{
  width: 50%;
  height: 50%;
}

.commandForm__header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.commandForm__header h2{
  cursor: pointer;
}

.commandForm__container{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.commandForm__container-questionSet{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid gray 2px;
  border-radius: 10px;
  padding: 2rem;
}