footer{
  padding: 30px 0 100px 0;
  box-shadow: 0 -5px 7px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom right, rgb(3, 102, 23), var(--main-color));
  position: relative;
}

footer, .footer__container{
  width: 100%;
  display: flex;
  color: white;
  font-size: 1.3rem;
  font-family: manjari;
}

.footer__container{
  justify-content: space-evenly;
  align-items: flex-start;
  scroll-margin-top: -20px;
}

.footer__container>section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.bar{
  width: 50px;
  height: 3px;
  background-color: var(--main-color);
}

.footer__service:hover .bar{
  background-color: red;
}

.footer-partA, .footer-partB, .footer-partC, .footer-partD{
  max-width: 25%;
}

.footer-partA > .footer__logo > img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  box-shadow: -5px -5px 0px #555;
}

.footer-partA h4{
  margin: 15px 0;
  text-decoration: underline;
}

.footer-partA > .social-networks {
  display: flex;
  justify-content: space-between;
}

.footer-partA > .social-networks > a{
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 50%;
  margin: 0 15px 0 0;
}

.footer-partA > .social-networks > a:hover{
  background-color: red;
}

.footer-partA > .social-networks > a > .social-netword__image{
  font-size: 2rem;
}

.footer-partB h4{
  margin: 0 0 15px 0;
  font-weight: 900;
  font-size: 1.5rem;
}

.footer-partB__container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-part__container-link{
  margin: 0 0 10px 0;
  text-transform: capitalize;
}

.footer-part__container-link:hover{
  text-decoration: underline;
}

.footer-partC h4{
  font-weight: 900;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.footer-partC__container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-partD{
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-partD > h1{
  font-size: 2rem;
  margin-bottom: 30px;
}

.footer-newsletter{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.footer-newsletter > input[type="text"]{
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.footer-newsletter > input[type="submit"]{
  height: 50px;
  margin-top: 5px;
  border: none;
  background: none;
  background-color: #319846;
  font-size: 1.2rem;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.footer__bottom{
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0;
  background-color: black;
  border-top: 1px solid white;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer__bottom > section{
  padding: 0 15px;
}

.footer__bottom-left{
  display: flex;
  align-items: center;
}

.footer__bottom-left .copywriting{
  border-right: solid 1px white;
  padding-right: 10px;
  margin-right: 10px;
}

.footer__bottom-left .location{
  display: flex;
  align-items: center;
}

.footer__bottom-right .politique{
  text-decoration: underline;
}



@media screen and (max-width: 1050px) {
  footer{
    padding: 16px 0 50px 0;
  }
  
  .footer__container{
    transform: scale(0.8);
    justify-content: space-between;
  }

  .footer__container > section{
    max-width: 21%;
  }

  .footer__bottom{
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 700px) {
  footer{
    padding: -10px 0 0 0;
  }
  
  .footer__container{
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -50px;
  }

  .footer__container > section{
    max-width: 40%;
    margin-bottom: 70px;
  }

  .footer__container h4{
    font-size: 1.5rem;
  }

  .footer__bottom{
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 500px) {
  
  .footer__container{
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: -80px;
  }

  .footer__container > section{
    max-width: 90%;
    margin-bottom: 70px;
  }

  .footer__container h4{
    font-size: 1.3rem;
  }

  .footer__bottom{
    font-size: 0.6rem;
  }

  .footer-partD{
    text-align: left;
  }

  .footer-newsletter{
    align-items: flex-start;
  }

  .footer-newsletter > input[type="submit"]{
    width: 40%;
  }
}