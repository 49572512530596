.banner{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-repeat: no-repeat;
}

.banner> .banner-partA{
  width: 40%;
  transition: 600ms;
}

.banner-partA > h2{
  font-size: 4.5rem;
}

.banner-partA > .banner-description{
  font-size:2.6rem;
  margin-bottom: 25px;
}

.banner-partA > .banner-slogang{
  font-size: 3.5rem;
}

.banner> .banner-partB{
  width: 45%;
}

.banner-partB > .img{
  margin: 20px;
  width: 400px;
  height: 400px;
  border: 5px solid transparent;
  flex-shrink: 0;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  margin: auto;
}

@media screen and (max-width: 1200px) { 
  .banner-partB > .img{
    margin: unset;
    width: 300px;
    height: 300px;
    
  }
}

@media screen and (max-width: 1050px) {
  .banner{
    height: unset;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 5rem;
  }

  .banner > .banner-partA, .banner > .banner-partB{
    width: 80%;
    margin: 0px;
  }

  .banner > .banner-partA{
    text-align: center;
  }

  .banner > .banner-partB > .img{
    width: 50%;
  }
}

@media screen and (max-width: 950px) {
  .banner > .banner-partA > h2{
    font-size: 3rem;
  }

  .banner > .banner-partA > .banner-description{
    font-size: 2rem
  }

  .banner > .banner-partA > .banner-slogang{
    font-size: 2.5rem;
  }
}