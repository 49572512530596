.slide{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  align-items: center;
  position: relative;
  text-align: center;
}

.slide > img{
  width: 500px;
  height: 500px;
  box-shadow: 0px -2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  object-fit: cover;
}

.slide-content{
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  color: white;
  background:  linear-gradient(to top, rgba(0, 0, 0, 1), rgba(100, 100, 100, 0));
  border-radius: 0 0 5px 5px;
  cursor:pointer;
  font-size: 2rem;
}

.slide:hover .slide-image{
  transition: 600ms;
}

.slide-content-header{
  padding: 15px;
}

.slide-content-header h1{
  text-transform: uppercase;
  text-decoration: underline;
}

.slide-content p{
  max-width: 100%;
  padding: 50px 10px;
  font-family: manjari;
  font-size: 1.5rem;
}

@media screen and (max-width: 750px) {

.slide-content-header h3{
  font-size: 1.5rem;
}
}
