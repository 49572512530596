.news__error{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: manjari;
  margin: 50px 0;
}

.news__error-image{
  font-size: 30rem;
  color: white;
  border-radius: 25% 50% 100% 25%;
  background-color: #888;
  box-shadow: 0 0 15px #777;
  margin: 30px 0;
}

.news__error div{
  color: var(--main-color);
  text-decoration: underline;
}

.news__error h1{
  color: #888;
}

.news__error p{
  font-size: 1.5rem;
  background-color: #888;
  color: white;
  padding: 10px 25px;
}

.news__container{
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.news__container-item{
  background-color: aliceblue;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0,0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: grayscale(50%);
  font-family: manjari;
}

.news__container-item:hover{
  filter: grayscale(0);
  transition: 400ms;
}

.news__container-item__image{
  border-radius: 20px 20px 0 0;
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.news__container-item:hover .news__container-item__image{
  width: 800px;
  transition: 400ms;
}

.news__container-item__title{
  margin: 10px 0;
}

@media screen and (max-width: 900px) {
  .news__container-item__image{
    width: 200px;
    height: 200px;
  }

  .news__container-item:hover .news__container-item__image{
    width: 400px;
    transition: 400ms;
  }

  .news__error >*, .news__error > * > * {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 700px) {
  .news__container-item:hover .news__container-item__image{
    width: 300px;
    transition: 400ms;
  }

  .news__error{
    margin-top: 0;
    padding-top: 100px
  }

  .news__error > *{
    margin: 20px 0;
  }

  .news__error-image{
    background-color: unset;
    color: #888;
    box-shadow: unset;
    font-size: 20em;
  }
}

@media screen and (max-width: 500px) {
  .news__container{
    flex-direction: column;
  }

  .news__container-item__image{
    width: 300px;
    height: 300px;
  }
  .news__container-item:hover .news__container-item__image{
    width: 400px;
    transition: 400ms;
  }
}

@media screen and (max-width: 400px) {
  .news__container-item:hover .news__container-item__image{
    width: 300px;
    transition: 400ms;
  }
}