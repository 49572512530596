:root{
  --main-color: #319846;
  --shadow-small: 2px 2px 5px rgba(0, 0, 0, 0.2), -2px 2px 5px rgba(0, 0, 0, 0.2),  2px -2px 5px rgba(0, 0, 0, 0.2), -2px -2px 5px rgba(0, 0, 0, 0.2);
  --secondary-family: manjari;
}

*{
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3198468c; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 5px;
}

@font-face {
  font-family: 'manjari';
  src: local('manjari') url("./assets/fonts/Manjari-Regular.otf") format('opentype');
}