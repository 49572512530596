.command_service{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.7);
  border-radius: 5px;
  color: black;
  margin: 5px;
  border: solid black 2px;
}

.command_service:hover{
  background-color: rgba(250, 250, 250, 1);
  transition: 300ms;
}

.command_service-aside{
  display: flex;
  align-items: center;
}

.command_service-checkbox{
  width: 20px;
  height: 20px;
  border: solid black 2px;
  cursor: pointer;
}

.command_service-checkbox:hover{
  border: solid 3px #319846;
  border-radius: 50%;
  transition: 300ms;
}

.command_service-more{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #fff;
  font-family: 'Century Gothic';
  font-size: 1rem;
  background-color: #319846;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor:copy;
}

.command_service-more:hover{
  color: red;
  transition: 300ms;
}