.about{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.about__menu{
  position: fixed;
  left: 0;
  top: 40%;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.about__menu a{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  width: 60px;
  height: 60px;
  margin: 2px 0;
  color: white;
  font-size: 1.5rem;
  transition: 400ms;
}

.about__menu a span{
  display: none;
  transition: 400ms;
}

.about__menu a:hover{
  justify-content: space-between;
  padding: 0 25px;
  border-radius: 0 60px 60px 0;
  width: 150px;
  background-color: red;
}

.about__menu a:hover span{
  display:contents;
}

.about__banner{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 300px;
  text-align: center;
}

.about__banner img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  border-radius: 0 0 10px 10px;
}

.about__banner-container{
  border-radius: 0 0 10px 10px;
  z-index: 1;
  color: white;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about__banner-container h1{
  font-size: 72px;
}

.about__banner-container p{
  font-size: 36px;
  font-family: var(--secondary-family);
  color: var(--main-color);
  font-weight: bold;
  position: relative;
}

.about__banner-container p > div{
  position: absolute;
  width: 25%;
  height: 5px;
  background-color: red;
  right: 0;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.8);
}

.about-content{
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.about-content > section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__pusher{
  height:0px;
}

.about-section{
  transition: 1s;
}

.about-section-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content .about-section{
  display: flex;
  width: 80%;
  margin: 100px 0;
  justify-content: space-between;
  align-items: flex-start;
}

.reversed{
  flex-direction: row-reverse;
}

.about-section__text-side{
  display: flex;
  flex-direction: column;
}

.reversed .about-section__text-side{
  align-items: flex-end;
}

.about-section-header{
  display: flex;
  flex-direction: column;
}

.reversed .about-section-header{
  align-items: flex-end;
  text-align: end;
}

.underline{
  color: var(--main-color);
  text-decoration: underline;
}
.about-section-header h1{
  font-size: 68px;
  color: black;
}

.about-section-header p{
  margin-top: 25px;
  font-family: var(--secondary-family);
  font-size: 36px;
  color: #bbb;
  font-weight: bold;
  max-width: 75%;
}

.about-section__text-side img{
  width: 40%;
  filter: grayscale(0.5);
}

.about-CTA{
  margin-top: 50px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--secondary-family);
  background-color: var(--main-color);
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  margin-bottom: 30px;
  transition: 400ms;
}

.about-CTA:hover{
  transform: scale(1.1);
  border: solid var(--main-color) 2px;
  background-color: none;
}

@media screen and (max-width: 1050px) {
  .about h1{
    font-size: 2.5rem;
  }

  .about__banner{
    height: 250px;
  }

  .about__banner-container p{
    font-size: 1.5rem;
  }

  .about__banner-container p > div{
    width: 25%;
    height: 3px;
    box-shadow: 0 -1.5px 1.5px rgba(0, 0, 0, 0.8);
  }

  .about-section-header p{
    font-size: 1.5rem;
  }

  .about-section__text-side img{
    width: 80%;
  }
}

@media screen and (max-width: 750px) {

  .about__menu{
    left: 0;
    top: unset;
    bottom: 0;
  }

  .about h1{
    font-size: 2rem;
  }

  .about__banner-container p{
    font-size: 1rem;
  }

  .about__banner-container p > div{
    width: 25%;
    height: 3px;
    box-shadow: unset;
  }

  .about-content .about-section{
    margin: 25px 0;
    flex-direction: column;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    justify-content: unset;
    align-items: center;
    padding-top: 20px;
    padding-bottom: -0px;
  }
  
  .about-section__text-side{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .reversed .about-section__text-side{
    align-items: center;
  }
  
  .about-section .about-section-header, .reversed .about-section-header{
    align-items: center;
    text-align: center;
  }

  .about-section-header p{
    font-size: 1rem;
  }

  .about-section__text-side img{
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .about {
    padding-top: 50px;
  }

  .about h1{
    font-size: 1.5rem;
  }

  .about__banner{
    height: 200px;
  }

  .about__banner-container p{
    font-size: 1rem;
  }

  .about-content{
    margin-top: 0;
  }

  .about__pusher{
    height:80px;
  }

  .about-section-header p{
    font-size: 0.8rem;
  }

  .about__menu{
    opacity: 0.4;
  }

  .about__menu:hover{
    opacity: 1;
  }

  .about-content .about-section{
    margin: 0;
  }

  .about-CTA{
    transform: scale(0.7);
  }
}

@media screen and (max-width: 450px) {

  .about__banner-container p{
    font-size: 0.7rem;
  }

  .about__banner-container p > div{
    display: none;
  }

  .about__menu{
    bottom: 30px;
    left: -10px;
    transform: scale(0.75);
  }
}

@media screen and (max-width: 350px) {
  .about__banner{
    height: 150px;
  }
}