.nos-services{
  padding-top: 50px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.nos-services__title{
  color: #319846;
  text-decoration: underline;
}

.nos-services__title h1{
  color: black;
}

.nos-services > div{
  display: flex;
  align-items: center;
  margin: 80px 0 10px 0;
}

.section-icon{
  width: 100px;
  margin-right: 10px;
  filter: grayscale(50);
}

.nos-services > .services{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}

.nos-services .services-cta{
  display: flex;
  background-color: #319846;
  padding: 10px 20px;
  font-size: 2rem;
  color: white;
}

.nos-services .services-cta:hover{
  box-shadow: 4px 4px 20px #ddd, 4px -4px 20px #ddd, -4px 4px 20px #777, -4px -4px 20px #ddd;
  transition: 600ms;
}

@media screen and (max-width: 750px) {
  .nos-services .services{
    flex-direction: column;
  }

  .nos-services{
    text-align: center;
  }
}