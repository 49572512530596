.service{
  display: block;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 300px;
  max-height: 300px;
  background-color: white;
  padding: 30px;
  margin: 30px;
  box-shadow: 0 3px 5px rgba(200, 200, 200, 1);
  border-radius: 5px;
  overflow: hidden;
  border:1px solid #ddd ;
  transition: 400ms;
  filter: grayscale(1);
}

.service:hover{
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  border: none;
  filter: grayscale(0);
}

.service > .service-header{
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.service > .service-header > .service-icon > img{
  width: 100px;
  margin-right: 15px;
}

.service > .service-description{
  margin-top: 30px;
  width: 100%;
  text-align: justify;
  font-size: 1.3rem;
  font-family: manjari;
  color: #555;
}

@media screen and (max-width: 950px) {
  .service .service-header{
    font-size: 1rem;
  }

  .service{
    height: 250px;
    max-height: 250px;
  }

  .service > .service-header > .service-icon > img{
    width: 70px;
  }

  .service > .service-description{
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 750px) {
  .service{
    min-width: 70%;
    padding: 0;
    height: unset;
    max-height: unset;
  }

  .service-description, .service-header{
    padding: 25px 20px;
  }

  .service > .service-description{
    margin: unset;
    width: unset;
    font-size: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .service{
    flex-direction: column;
    padding: 20px 10px;
  }

  .service-description, .service-header{
    padding: 0;
    margin: 0 0 20px 0;
  }

  .service > .service-description{
    margin: unset;
    width: unset;
  }

  .service > .service-header{
    width: 100%;
    background-color: unset;
    color: #000;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .service{
    max-width: 70px;
  }

  .service > .service-header{
    flex-direction: column;
  }

  .service > .service-header > .service-icon > img{
    width: 50px;
  }

  .service > .service-description{
    font-size: 0.8rem;
  }
}