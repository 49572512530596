#reduced-header{
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  padding: 10px 0px;
  z-index: 10;
  font-family: manjari;
  color: black;
  font-weight:900;
  font-size: 1.1rem;
  max-height: 100px;
  position: relative;
}

.logo{
  display: flex;
  align-items: center;
  margin-left: 40px;
  font-size: 1.5rem;
  font-family: 'Agency FB';
}

.logo>img{
  width:60px;
  height: 60px;
  margin-right: 5px;
}

.logo span{
  margin-left: 5px;
}

#reduced-header > nav{
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: manjari;
  position: relative;
}

nav .nav-link{
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  transition: 200ms;
  text-align: center;
}

nav .active-link{
  height: 25px;
  line-height: 25px;
  background-color: var(--main-color);
  color: white;
  padding: 5px 20px;
  border-radius: 50px;
  vertical-align: bottom;
}

nav .unActive-link:hover{
  color: var(--main-color);
}

nav .active-link:hover{
  color: white;
}

nav .about-button{
  display: none;
}



.nav-link_emph{
  background-color: var(--main-color);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  padding: 7.5px 10px 5px 10px;
  display: inline-block;
  margin-right: 40px;
}

.nav-link_emph:hover{
  border-bottom: none;
  transform: scale(1.1);
  transition: 600ms;
}

.small-nav{
  display: none;
  align-items: center;
}

.toggleMenu{
  display: none;
}

.aboutIcon{
  margin: auto;
  margin-right: 2rem;
  font-size: 1.8rem;
  color: var(--main-color);
  display: none;
}


@media screen and (max-width: 1050px) {
  #reduced-header{
    max-width: 1050px;
  }

  #reduced-header > nav{
    width: 40%;
  }

  .nav-link_emph{
    display: none;
  }

  .small-nav{
    display: flex;
  }

  .aboutIcon{
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  #reduced-header > nav{
    width: 50%;
  }
}

@media screen and (max-width: 750px){

  #reduced-header > nav{
    display: none;

  }

  .small-nav{
    display: flex;
  }

  .toggleMenu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content:flex-end;
    cursor: pointer;
    position: relative;
    margin-right: 40px;
  }

  .toggleMenuButton{
    font-size: 2.3rem;
    display: flex;
  }

  .toggleMenu:hover> .toggleMenuButton{
    color: var(--main-color);
  }

  .toggleMenu nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: #888;
    background-color: black ;
    top: 70px;
    right: 0px;
    padding: 15px 0px;
    border-radius: 7px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }

  .toggleMenu nav > div{
    padding: 0;
  }

  .toggleMenu .nav-link{
    padding: 10px 0px;
    width: 100%;
  }

  .toggleMenu .nav-link:hover, .toggleMenu .active-link{
    color: white;
    background-color: var(--main-color);
  }

  .toggleMenu .active-link{
    border-radius: unset;
  }

  .toggleMenu nav .servicesMenu__wrapper{
    max-height: 250px;
    overflow: auto;
    position: relative;
  }

  .nav-link_emph{
    margin: 0;
  }
}

@media screen and (max-width: 650px){

  .logo{
    margin-left: 20px;
  }
  
  .aboutIcon:hover{
    transform: scale(1.1);
  }

  .nav-link_emph{
    display: none;
  }

  .toggleMenu{
    margin-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  #reduced-header{
    max-height: 50px;
  }

  .toggleMenu{
    position: relative;
  }

 .aboutIcon{
   margin-right: 10px;
 }
}

@media screen and (max-width: 450px) {
  #reduced-header{
    position: fixed;
  }

  .aboutIcon{
    display: none;
  }

  nav .about-button{
    display: block;
  }
}

@media screen and (max-width: 350px){
  #reduced-header{
    max-height: 30px;
    padding: none;
  }

  .logo{
    z-index: inherit;
    max-width: 50%;
  }

  .logo span{
    font-size: 1.2rem;
    max-width: 50%;
    line-height: 12px;
    margin: 0;
  }

  .logo img{
    width: 30px;
    height: 30px;
  }

  .small-nav{
    position: absolute;
  }

  .small-nav, .toggleMenu, .toggleMenu nav{
    width: 100%;
  }

  .toggleMenu{
    margin: 0;
    align-items: flex-end;
  }

  .toggleMenu .toggleMenuButton{
    margin-right: 10px;
  }
  .toggleMenu nav{
    border-radius: 0;
    top: 100%;
    height: 80vh;
    max-height: 80vh;
    overflow: auto;
    overflow-x:hidden ;
  }
} 