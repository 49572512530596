*{
  margin: 0;
  background-repeat: no-repeat;
  font-size: 95%;
}

body{
  font-family: 'Agency FB';
}

a, link{
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button{
  border: none;
  background-color: none;
  color: inherit;
  cursor: pointer;
}

.App{
  width: 100%;
}