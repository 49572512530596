.service_page{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/servicesBg-heavy.jpg') fixed;
  color: white;
}

.service-top{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.service-top-image{
  width: 300px;
  height: 300px;
  background-color: rgba(49, 152, 70, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.service-top-image>.image>div{
  filter:sepia(0.75);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 4px 4px 10px #222, 4px -4px 10px #222, -4px 4px 10px #222, -4px -4px 10px #222;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.service-top-image img{
  width: 70%;
}

.service-top-content{
  width: 45%;
  border-left: solid red 3px;
  padding-left: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.service-top-content__header-bar{
  width: 50%;
  height: 5px;
  background-color: white;
  background: linear-gradient(to right, var(--main-color), red);
  display: none;
}

.service-top-content-header>h1{
  font-size: 2.5rem;
  text-transform:uppercase;
}

.service-top-content-header>p{
  font-size: 1.5rem;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: var(--secondary-family);
  text-align: justify;
}

.service-top-content-team{
  display: flex;
  margin: 25px 0;
  justify-content: space-between;
}

.service-top-content-team-member{
  width: 75px;
  height: 75px;
  background-color: black;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}

.service-top-content-team-member:hover{
  background-color: var(--main-color);
  transition: 300ms;
}

.service-top-content-stats{
  width: 75%;
  height: 50px;
  border-radius: 5px;
  border: solid black 2px;
}

.service_page-cta{
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  width: 25%;
  min-width: 200px;
  align-items: center;
  padding: 10px;
  background-color: var(--main-color);
  border-radius: 5px;
  font-size: 2rem;
}

@media screen and (max-width: 1050px) {

  .service_page{
  background: url('../../assets/servicesBg.jpg') fixed center;
    height: unset;
  }

  .service_page .service-top{
    transform: scale(0.6);
  }
  
}

@media screen and (max-width: 950px) {

  .service_page{
    width: 100%;
    margin-top: unset;
  }

  .service_page .service-top{
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .service-top-image{
    width: 250px;
    height: 250px;
    background-color: rgba(49, 152, 70, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: auto;
  }
  
  .service-top-image>.image>div{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: auto;
    box-shadow: 4px 4px 10px #222, 4px -4px 10px #222, -4px 4px 10px #222, -4px -4px 10px #222;
  }

  .service-top-content{
    width: 100%;
    padding-left: unset;
    border-left: unset;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
  
  .service-top-content-header{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .service-top-content__header-bar{
    display: unset;
  }

  .service-top-content__header-bar_top{
    box-shadow: 0 4px 4px rgba(0,0,0,0.5);
  }
  .service-top-content__header-bar_bottom{
    box-shadow: 0 -4px 4px rgba(0,0,0,0.5);
  }
  
  .service-top-content-header>p{
    width: 100%;
    position: unset;
  }
 
}

@media screen and (max-width: 550px) {


  .service_page *{
    margin: 0;
  }

  .service-top-image,
  .service-top-content,
  .service-top-content-header,
  .service-top-content__header-bar_bottom,
  .service-top-content-header p,
  .service-top-content-team,
  .service-top-content-stats
  {
    margin-bottom: 20px;
  }
  
  .service-top-content-header h1{
    font-size: 2rem;
  }
  
  .service-top-content-header p{
    font-size: 1.5rem;
  }

  .service-top-content-team-member{
    margin: 2px;
  }
}