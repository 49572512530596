@media screen and (max-width: 950px) {
  .slider{
    transform: scale(0.9);
    margin: -10%;
  }
}

@media screen and (max-width: 750px){
  .slider{
    transform: scale(0.7);
    margin: -15%
  }

  .slider > div:last-child{
    transform: scale(0.7);
  }
}

@media screen and (max-width: 550px) {
  .slider .slide-image{
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 375px) {
  .slider .slide-image{
    height: 300px;
    width: 300px;
  }
}